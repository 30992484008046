//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Popfield from "./components/Popfield.vue";
import { Toast } from "vant";
import qs from "qs";
export default {
  components: {
    "pop-field": Popfield,
  },
  data() {
    return {
      enteryData: {},
      tempData: {
        _status: "create",
        hhrEmpid: "",
        hhrEmpName: "",
        hhrEmpSex: "",
        hhrDeptCode: "",
        hhrPosnCode: "",
        hhrChannel: "20",
        hhrWfOrgPerProbationPeriodAttr02: "",
        hhrWfOrgPerProbationPeriodAttr02Meaning: "",
        hhrGuidanceCode: "",
        hhrGuidanceName: "",
        hhrGuidanceDeptCode: "",
        hhrGuidanceDeptName: "",
        hhrGuidancePosnCode: "",
        hhrGuidancePosnName: "",
        hhrProbationWorkSummary: "",
      },

      leaderNameArr: [],
      leaderNamePicker: false,
      leaderSearch: "",
      leadPost: {
        hhrEmpName: "",
        hhrEmpid: "",
        page: "",
      },
      saveSate: false,
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    onSubmit() {
      this.tempData.hhrEmpid = this.enteryData.hhrEmpId;
      this.tempData.hhrEmpName = this.enteryData.hhrEmpName;
      this.tempData.hhrEmpSex = this.enteryData.hhrGender;
      this.tempData.hhrDeptCode = this.enteryData.hhrDeptCode;
      this.tempData.hhrPosnCode = this.enteryData.hhrPosnCode;

      let postData = {
        pathVariableMap: {
          tenantId: "0",
        },
        headerParamMap: {},
        requestParamMap: {
          reqType: "save",
          entityName:
            "com.boogoo.hhr.corehr.wf.domain.entity.WfOrgPerProbationPeriod",
        },
        payload: JSON.stringify([this.tempData]),
      };
      console.log(this.tempData);

      this.saveSate = true;
      this.axios
        .post("/boogooForm/excuteMethod", postData, {
          headers: {
            processDefinitionKey: "PER_QUALIFIED_NEW_DEF",
            employeeNum: this.$memberInfo.hhrEmpid,
          },
        })
        .then((res) => {
          console.log(res);

          if (res && res.data) {
            this.saveSate = false;
            if (res.data.status == "200") {
              let resultData = JSON.parse(res.data.payload);
              if (resultData.failed) {
                this.$q.notify({
                  color: "red-5",
                  textColor: "white",
                  icon: "warning",
                  position: "center",
                  message: resultData.message,
                  timeout: 1000,
                });
              } else {
                this.$q.notify({
                  type: "positive",
                  color: "grey-8",
                  textColor: "white",
                  position: "center",
                  message: "提交成功！",
                  timeout: 1000,
                });
                this.$router.replace("/oaplatform/list");
              }
            }
          }
        })
        .catch(() => {
          this.saveSate = false;
          Toast("请求出错了");
        });
    },

    loadData() {
      let postData = {
        pathVariableMap: {
          organizationId: "0",
        },
        headerParamMap: {},
        requestParamMap: {
          hhrEmpid: this.$memberInfo.hhrEmpid,
        },
        payload: "{}",
      };

      Toast.loading({
        message: "数据加载中...",
        duration: 0,
      });
      this.axios
        .post("/boogoo/personInfoByhhrEmpidPost", postData)
        .then((res) => {
          Toast.clear();
          if (res && res.data) {
            if (res.data.status == "200") {
              let resultData = JSON.parse(res.data.payload);
              console.log("personInfoByhhrEmpidPost", resultData);
              if (resultData.failed) {
                this.saveSate = false;
                this.$q.notify({
                  color: "red-5",
                  textColor: "white",
                  icon: "warning",
                  position: "center",
                  message: resultData.message,
                  timeout: 1000,
                });
              } else {
                this.enteryData = resultData;
                console.log("enteryData", this.enteryData);
              }
            }
          }
        })
        .catch(function () {
          Toast.clear();
          Toast("请求出错了");
        });
    },
    showLeaderList(reload) {
      if (!reload && this.leaderNameArr.length > 0) {
        this.leaderNamePicker = true;
        return;
      }
      Toast.loading({
        message: "数据加载中...",
        duration: 0,
      });
      this.axios
        .post("/boogoo/queryPerBiogLovValues", qs.stringify(this.leadPost))
        .then((res) => {
          Toast.clear();
          this.leaderNamePicker = true;
          if (res && res.data) {
            if (res.data.status == "200") {
              let resultData = JSON.parse(res.data.payload);
              this.buildleaderArr(resultData.content);
              console.log(resultData);
            }
          }
        })
        .catch(function () {
          Toast.clear();
          Toast("请求出错了");
        });
    },

    popConfirm(obj) {
      switch (obj.key) {
        case "hhrGuidanceCode":
          this.tempData.hhrGuidanceDeptName = obj.value.obj.hhrDeptName;
          this.tempData.hhrGuidanceDeptCode = obj.value.obj.hhrDeptCode;
          this.tempData.hhrGuidancePosnName = obj.value.obj.hhrPosnName;
          this.tempData.hhrGuidancePosnCode = obj.value.obj.hhrPosnCode;
          this.tempData.hhrGuidanceCode = obj.value.value;
          break;
        default:
          this.tempData[obj.key] = obj.value.value;
      }
    },
  },
  mounted() {
    document.title = "试用期转正申请";
  },
};
